<template>
  <div id="register">
    <h2>注册</h2>
    <h3>请选择您要注册的企业类型</h3>
    <ul>
      <li :class="{ active: type === 'a' }" @click="change('a')">
        <p>集成类公司</p>
        <p>
          这里是一段集成类公司的介绍，这里是一段集成类公司的介绍，这里是一段集成类公司的介绍，这里是一段集成类公司的介绍。这里是一段集成类公司的介绍，这里是一段集成类公司的介绍。
        </p>
      </li>
      <li :class="{ active: type === 'b' }" @click="change('b')">
        <p>供应商公司</p>
        <p>
          这里是一段集成类公司的介绍，这里是一段集成类公司的介绍，这里是一段集成类公司的介绍，这里是一段集成类公司的介绍。这里是一段集成类公司的介绍，这里是一段集成类公司的介绍。
        </p>
      </li>
      <li :class="{ active: type === 'c' }" @click="change('c')">
        <p>招标方公司</p>
        <p>
          这里是一段集成类公司的介绍，这里是一段集成类公司的介绍，这里是一段集成类公司的介绍，这里是一段集成类公司的介绍。这里是一段集成类公司的介绍，这里是一段集成类公司的介绍。
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "register",
  data() {
    return {
      type: "a",
    };
  },
  methods: {
    change(v) {
      this.type = v;
      this.$router.push(`/${this.type}-register`);
    },
  },
};
</script>

<style lang="scss" scoped>
#register {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  height: auto;
  overflow: hidden;
  background: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 68px 0 0 0;
  padding-bottom: 50px;
  h2 {
    line-height: 40px;
    font-size: 36px;
    color: #333;
    text-align: center;
    font-weight: normal;
    margin: 104px 0 64px 0;
  }
  h3 {
    line-height: 28px;
    font-size: 20px;
    color: #333;
    text-align: center;
    font-weight: normal;
  }
  ul {
    width: 1300px;
    margin: 50px 0 100px;
    list-style: none;
    display: flex;
    // flex-direction: column;
    justify-content: space-around;
    li {
      width: 400px;
      // min-height: 224px;
      height: auto;
      border: 1px solid #e6ebf4;
      background: #fff;
      margin: 32px 0 0;
      padding: 32px;
      cursor: pointer;
      p {
        &:first-child {
          line-height: 30px;
          font-size: 20px;
          color: #263238;
        }
        &:last-child {
          line-height: 24px;
          font-size: 18px;
          color: #666;
          margin: 20px 0 0;
        }
      }
      // &.active {
      //   box-shadow: 0px 0px 40px 4px rgba(56, 131, 255, 0.4);
      //   border: 1px solid #fff;
      // }
    }
    li:hover {
      box-shadow: 0px 0px 40px 4px rgba(56, 131, 255, 0.4);
      border: 1px solid #fff;
    }
  }
  div {
    height: 50px;
    display: flex;
    margin: 64px 0 106px;
    a {
      width: 136px;
      line-height: 48px;
      border-width: 1px;
      border-style: solid;
      font-size: 18px;
      margin: 0 16px;
      text-align: center;
      text-decoration: none;
      cursor: pointer;
      &:first-child {
        border-color: #3883ff;
        background: #3883ff;
        color: #fff;
      }
      &:last-child {
        border-color: #dadada;
        color: #333;
      }
    }
  }
}
</style>
