<template>
  <div id="register-success">
    <div class="success-cont">
      <h2>加入我们</h2>
      <p>您的注册信息已提交</p>
      <p>
        您的注册信息已提交，我们将会在3个工作日内审核完成，审核结果装发送到您注册的手机短信中，如果疑问请联系我们
        800-800-0000。
      </p>
      <p>感谢您的支持和配合。</p>
      <router-link to="/">返回首页</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "register-success",
};
</script>

<style lang="scss" scoped>
#register-success {
  width: 100%;
  max-width: 1920px;
  height: auto;
  overflow: hidden;
  background: #f9f9f9;
  margin: 0 auto;
  padding: 68px 0 0;
  .success-cont {
    width: 1200px;
    height: 800px;
    border: 1px solid #ededed;
    background: #fff;
    text-align: center;
    margin: 70px auto 114px;
    h2 {
      line-height: 40px;
      font-size: 28px;
      color: #333;
      font-weight: normal;
      margin: 120px 0 64px;
    }
    p {
      width: 900px;
      margin: 0 auto;
      text-align: left;
      line-height: 48px;
      text-indent: 2em;
      font-size: 18px;
      color: #333;
      &:nth-of-type(1) {
        line-height: 28px;
        font-size: 20px;
        color: #3883ff;
        text-indent: 0;
        margin: 0 auto 60px;
      }
      &:nth-of-type(3) {
        margin: 10px auto 0;
      }
    }
    a {
      width: 136px;
      height: 50px;
      background: #3883ff;
      line-height: 50px;
      text-align: center;
      font-size: 18px;
      color: #fff;
      text-decoration: none;
      display: inline-block;
      margin: 168px 0 0;
    }
  }
}
</style>
