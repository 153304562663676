<template>
  <div class="page-container b-register-view">
    <div class="page-box">
      <div class="box-title">注册</div>
      <el-form
        v-show="formStep === 1"
        class="box-form"
        ref="form"
        label-position="left"
        :model="form"
        :rules="formRules"
        label-width="200px"
      >
        <el-form-item label="请填写以下公司信息" class="form-title" />
        <el-form-item label="企业Logo">
          <el-upload
            class="image-item logo-item"
            :class="{ showImage: form.logoF.length > 0 }"
            action=""
            :auto-upload="false"
            :file-list="form.logoF"
            list-type="picture"
            :on-change="picturechange.bind(this, 'logoF')"
            :on-remove="fileRemove.bind(this, 'logoF')"
            :limit="1"
            accept="image/*"
          >
            <div class="addImage logo">上传图片</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="统一社会信用代码" prop="code">
          <el-input
            v-model="form.code"
            placeholder="请输入统一社会信用代码"
            type="text"
          />
          <p
            style="
              color: #aaa;
              font-size: 10px;
              position: absolute;
              top: 0;
              left: 260px;
            "
          >
            登录时可作为账号使用
          </p>
        </el-form-item>
        <el-form-item label="公司名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入公司名称" />
        </el-form-item>
        <el-form-item label="所属地区">
          <el-cascader
            :options="options"
            v-model="form.selectedOptions"
            @change="handleCascader"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="所属行业" prop="industry">
          <el-input v-model="form.industry" placeholder="请输入所属行业" />
        </el-form-item>
        <el-form-item label="设置登录密码" prop="pwd">
          <el-input
            v-model="form.pwd"
            type="password"
            placeholder="请输入密码"
          />
          <p
            style="
              color: #aaa;
              font-size: 10px;
              position: absolute;
              top: 0;
              left: 260px;
            "
          >
            此密码为账户登录密码，请牢记
          </p>
        </el-form-item>
        <el-form-item label="确认登录密码" prop="repwd">
          <el-input
            v-model="form.repwd"
            type="password"
            placeholder="请再次输入密码"
          />
        </el-form-item>
        <el-form-item label="高新技术企业认证" prop="tech">
          <el-radio-group v-model="form.tech">
            <el-radio v-for="item in techList" :key="item" :label="item">{{
              item
            }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="其他资质/荣誉">
          <el-checkbox-group
            v-model="form.otherList"
            style="
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              width: 500px;
            "
          >
            <el-checkbox
              v-for="item in otherList"
              :key="item.id"
              :label="item.id"
              style="line-height: 40px"
              >{{ item.value }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>

        <el-form-item label="经办人信息" class="sub-title" />
        <el-form-item label="经办人姓名" prop="operator">
          <el-input v-model="form.operator" placeholder="请输入经办人姓名" />
        </el-form-item>

        <el-form-item label="经办人电话" prop="bindPhone">
          <el-input
            v-model="form.bindPhone"
            type="text"
            placeholder="请填写经办人手机"
            id="phone"
          />
          <p
            style="
              color: #aaa;
              font-size: 10px;
              position: absolute;
              top: 0;
              left: 260px;
            "
          >
            此手机号可做登录使用
          </p>
        </el-form-item>
        <el-form-item label="经办人邮箱" prop="mail">
          <el-input
            v-model="form.mail"
            type="email"
            placeholder="请输入经办人邮箱"
          />
        </el-form-item>
      </el-form>
      <el-form
        v-show="formStep === 2"
        class="box-form"
        ref="form"
        label-position="left"
        :model="form"
        :rules="formRules"
        label-width="300px"
      >
        <el-form-item
          label="请上传以下资质文件（加盖公章为有效文件）"
          class="form-title"
        />
        <el-form-item label="身份证正反面">
          <el-upload
            class="file-item"
            action=""
            :auto-upload="false"
            :file-list="form.idCardF"
            :on-change="fileChange.bind(this, 'idCardF')"
            :on-remove="fileRemove.bind(this, 'idCardF')"
            :limit="1"
            accept=".pdf"
          >
            <el-button slot="trigger" size="small" type="primary"
              >上传</el-button
            >
          </el-upload>
          <div v-if="form.idCardF.length > 0">
            <p
              class="show"
              @click="handleClick('idCardF', '身份证正反面', form.idCardF)"
            >
              预览
            </p>
          </div>
        </el-form-item>
        <!-- <el-form-item label="身份证国徽面">
          <el-upload
            class="file-item"
            action=""
            :file-list="form.idCard2F"
            :auto-upload="false"
            :on-change="fileChange.bind(this, 'idCard2F')"
            :on-remove="fileRemove.bind(this, 'idCard2F')"
            :limit="1"
            accept=".pdf"
          >
            <el-button slot="trigger" size="small" type="primary"
              >上传</el-button
            >
          </el-upload>
          <div v-if="form.idCard2F.length > 0">
            <p
              class="show"
              @click="handleClick('idCard2F', '身份证国徽面', form.idCard2F)"
            >
              预览
            </p>
          </div>
        </el-form-item> -->
        <el-form-item label="公司授权委托书">
          <el-upload
            class="file-item"
            action=""
            :file-list="form.certF"
            :auto-upload="false"
            :on-change="fileChange.bind(this, 'certF')"
            :on-remove="fileRemove.bind(this, 'certF')"
            :limit="1"
            accept=".pdf"
          >
            <el-button slot="trigger" size="small" type="primary"
              >上传</el-button
            >
          </el-upload>
          <div v-if="form.certF.length > 0">
            <p
              class="show"
              @click="handleClick('certF', '公司授权委托书', form.certF)"
            >
              预览
            </p>
          </div>
        </el-form-item>
        <el-form-item label="高新技术企业认证文件" v-show="form.tech !== '无'">
          <el-upload
            class="file-item"
            action=""
            :file-list="form.techF"
            :auto-upload="false"
            :on-change="fileChange.bind(this, 'techF')"
            :on-remove="fileRemove.bind(this, 'techF')"
            :limit="1"
            accept=".pdf"
          >
            <el-button slot="trigger" size="small" type="primary"
              >上传</el-button
            >
          </el-upload>
          <div v-if="form.techF.length > 0">
            <p
              class="show"
              @click="handleClick('techF', '高新企业认证文件', form.techF)"
            >
              预览
            </p>
          </div>
        </el-form-item>
        <el-form-item
          label="其他类型资质文件"
          v-if="this.form.otherList.length > 0"
        />
        <el-form-item
          label="涉密系统集成资质认证文件"
          v-if="this.form.otherList.includes(0)"
        >
          <el-upload
            class="file-item"
            action=""
            :file-list="form.otherSeurityF"
            :auto-upload="false"
            :on-change="fileChange.bind(this, 'otherSeurityF')"
            :on-remove="fileRemove.bind(this, 'otherSeurityF')"
            accept=".pdf"
            :limit="1"
          >
            <el-button slot="trigger" size="small" type="primary"
              >上传</el-button
            >
          </el-upload>
          <div v-if="form.otherSeurityF.length > 0">
            <p
              class="show"
              @click="
                handleClick(
                  'otherSeurityF',
                  '涉密系统集成资质认证文件',
                  form.otherSeurityF
                )
              "
            >
              预览
            </p>
          </div>
        </el-form-item>
        <el-form-item
          label="双软企业认证文件"
          v-if="this.form.otherList.includes(1)"
        >
          <el-upload
            class="file-item"
            action=""
            :file-list="form.otherTwoF"
            :auto-upload="false"
            :on-change="fileChange.bind(this, 'otherTwoF')"
            :on-remove="fileRemove.bind(this, 'otherTwoF')"
            accept=".pdf"
            :limit="1"
          >
            <el-button slot="trigger" size="small" type="primary"
              >上传</el-button
            >
          </el-upload>
          <div v-if="form.otherTwoF.length > 0">
            <p
              class="show"
              @click="
                handleClick('otherTwoF', '双软企业认证文件', form.otherTwoF)
              "
            >
              预览
            </p>
          </div>
        </el-form-item>
        <el-form-item
          label="CMMI认证文件"
          v-if="this.form.otherList.includes(2)"
        >
          <el-upload
            class="file-item"
            action=""
            :file-list="form.otherCmmitF"
            :auto-upload="false"
            :on-change="fileChange.bind(this, 'otherCmmitF')"
            :on-remove="fileRemove.bind(this, 'otherCmmitF')"
            accept=".pdf"
            :limit="1"
          >
            <el-button slot="trigger" size="small" type="primary"
              >上传</el-button
            >
          </el-upload>
          <div v-if="form.otherCmmitF.length > 0">
            <p
              class="show"
              @click="
                handleClick('otherCmmitF', 'CMMI认证文件', form.otherCmmitF)
              "
            >
              预览
            </p>
          </div>
        </el-form-item>
        <el-form-item
          label="ITSS认证文件"
          v-if="this.form.otherList.includes(3)"
        >
          <el-upload
            class="file-item"
            action=""
            :file-list="form.otherItssF"
            :auto-upload="false"
            :on-change="fileChange.bind(this, 'otherItssF')"
            :on-remove="fileRemove.bind(this, 'otherItssF')"
            accept=".pdf"
            :limit="1"
          >
            <el-button slot="trigger" size="small" type="primary"
              >上传</el-button
            >
          </el-upload>
          <div v-if="form.otherItssF.length > 0">
            <p
              class="show"
              @click="
                handleClick(
                  'otherItssF',
                  'ITSS认证文件',
                  form.antotherItssFiSafeF
                )
              "
            >
              预览
            </p>
          </div>
        </el-form-item>
        <el-form-item label="CS认证文件" v-if="this.form.otherList.includes(4)">
          <el-upload
            class="file-item"
            action=""
            :file-list="form.otherCsF"
            :auto-upload="false"
            :on-change="fileChange.bind(this, 'otherCsF')"
            :on-remove="fileRemove.bind(this, 'otherCsF')"
            accept=".pdf"
            :limit="1"
          >
            <el-button slot="trigger" size="small" type="primary"
              >上传</el-button
            >
          </el-upload>
          <div v-if="form.otherCsF.length > 0">
            <p
              class="show"
              @click="handleClick('otherCsF', 'CS认证文件', form.otherCsF)"
            >
              预览
            </p>
          </div>
        </el-form-item>
        <el-form-item
          label="ISO认证文件"
          v-if="this.form.otherList.includes(5)"
        >
          <el-upload
            class="file-item"
            action=""
            :file-list="form.otherIsoF"
            :auto-upload="false"
            :on-change="fileChange.bind(this, 'otherIsoF')"
            :on-remove="fileRemove.bind(this, 'otherIsoF')"
            accept=".pdf"
            :limit="1"
          >
            <el-button slot="trigger" size="small" type="primary"
              >上传</el-button
            >
          </el-upload>
          <div v-if="form.otherIsoF.length > 0">
            <p
              class="show"
              @click="handleClick('otherIsoF', 'ISO认证文件', form.otherIsoF)"
            >
              预览
            </p>
          </div>
        </el-form-item>
      </el-form>

      <el-dialog
        :title="eldialogTitle"
        :visible.sync="isViewPdf20"
        :fullscreen="true"
      >
        <iframe
          :src="eldialogUrl"
          frameborder="0"
          style="width: 100%; height: 85vh"
        ></iframe>
      </el-dialog>

      <div class="form-action">
        <el-button type="primary" @click="prevStep">上一步</el-button>
        <el-button v-if="formStep < 2" type="primary" @click="nextStep"
          >下一步</el-button
        >
        <el-button v-else type="primary" @click="submit(form)">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { regionData, CodeToText } from "element-china-area-data";
import { mobile } from "@/utils/regex";

export default {
  name: "a-register",
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "" && this.form.pwd) {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.pwd) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    var code = (rule, value, callback) => {
      let re = /[a-zA-Z0-9]{18}/;
      if (value === "") {
        callback(new Error("请输入统一社会信用代码"));
      } else if (value.length === 18 && re.test(value)) {
        callback();
      } else {
        callback(new Error("请输入18位正确的统一社会信用代码"));
      }
    };
    var bindPhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入经办人手机号码"));
      } else if (value.length != 11 || !mobile.test(value)) {
        callback(new Error("请输入正确的号码格式"));
      } else {
        this.flag = true;
        callback();
      }
    };
    return {
      isViewPdf20: false,
      flag: false,
      options: regionData,
      eldialogTitle: "",
      eldialogUrl: "",
      techList: ["国家高新", "省高新", "无"],
      otherList: [
        { id: 0, value: "涉密系统集成资质" },
        { id: 1, value: "双软企业" },
        { id: 2, value: "CMMI" },
        { id: 3, value: "ITSS" },
        { id: 4, value: "CS" },
        { id: 5, value: "ISO" },
      ],
      formStep: 1,
      form: {
        logoF: [],
        code: "",
        name: "",
        bindPhone: "",

        selectedOptions: "",
        industry: "",
        provice: "",
        city: "",
        district: "",

        tech: "无",
        techF: [],
        techFUrl: "",

        otherList: [],

        bidAmount: "",
        salse: "",
        operator: "",
        mail: "",
        quota: "",
        pwd: "",
        repwd: "",

        idCardF: [],
        idCardFUrl: "",

        idCard2F: [],
        idCard2FUrl: "",

        certF: [],
        certFUrl: "",

        otherSeurityF: [],
        otherSeurityFUrl: "",

        otherTwoF: [],
        otherTwoFUrl: "",

        otherCmmitF: [],
        otherCmmitFUrl: "",

        otherItssF: [],
        otherItssFUrl: "",

        otherCsF: [],
        otherCsFUrl: "",

        otherIsoF: [],
        otherIsoFUrl: "",

        type: "2",
        memberLength: 6,
      },
      formRules: {
        name: [
          {
            required: true,
            message: "请输入公司名称",
            trigger: "blur",
          },
        ],
        pwd: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
        operator: [
          {
            required: true,
            message: "请输入经办人姓名",
            trigger: "blur",
          },
        ],
        repwd: {
          required: true,

          validator: validatePass,
          trigger: "blur",
        },
        code: {
          required: true,

          validator: code,
          trigger: "blur",
        },
        bindPhone: {
          required: true,
          validator: bindPhone,
          trigger: "blur",
        },
        tech: {
          required: true,
        },
      },
    };
  },
  methods: {
    handleCascader() {
      var loc = "";
      for (let i = 0; i < this.form.selectedOptions.length; i++) {
        loc += CodeToText[this.form.selectedOptions[i]];
      }
    },
    picturechange(key, file, fileList) {
      //logo上传图片
      this.form[key] = fileList;
    },
    handleClick(item, title, file) {
      this.isViewPdf20 = false;
      let url = item + "Url";
      var fileName = file[0].name;
      var fileNameIndex = fileName.lastIndexOf(".");
      var newfileName = fileName.substring(fileNameIndex, fileName.length);
      if (newfileName === ".pdf") {
        this.isViewPdf20 = true;
        this.eldialogTitle = title;
        this.eldialogUrl = this.form[url];
      } else {
        this.$alert(
          "<strong><img src=" +
            this.form[url] +
            " width=470px height=357px/></strong>",
          title,
          {
            dangerouslyUseHTMLString: true,
          }
        ).then((action) => {
          console.log(this.buyInsurancePrintTicket());
        });
      }
    },
    prevStep() {
      if (this.formStep === 1) {
        // TODO
      } else {
        this.formStep -= 1;
      }
    },
    nextStep() {
      if (this.flag && this.form.bindPhone) {
        // console.log(this.form.bindPhone);
        let phone = document.getElementById("phone");
        this.$api.getBindPhoneIsFlag(this.form.bindPhone).then((res) => {
          // console.log(res);
          if (res.state == 200 && res.data) {
            this.$message.error(res.message);
            phone.focus();
            this.flag = false;
          } else {
            this.formStep += 1;
          }
        });
      }
    },
    fileChange(key, file, fileList) {
      var fileName = file.name;
      var fileNameIndex = fileName.lastIndexOf(".");
      var newfileName = fileName.substring(fileNameIndex, fileName.length);
      if (newfileName != ".pdf") {
        this.$message.error("上传文件只能是 PDF 格式!");
        fileList[0] = "";
      } else {
        if (this.form[key] || this.form[urlKey]) {
          this.form[key] = [];
          this.form[urlKey] = "";
        }
        this.form[key] = fileList;
        let reader = new FileReader();
        reader.readAsDataURL(file.raw);
        let URL = window.URL || window.webkitURL;
        let url = URL.createObjectURL(file.raw);
        var urlKey = key + "Url";
        this.form[urlKey] = url;
      }
    },
    fileRemove(key, file, fileList) {
      this.form[key] = fileList;
    },
    async submit() {
      let _this = this;
      this.form.provice = CodeToText[this.form.selectedOptions[0]];
      this.form.city = CodeToText[this.form.selectedOptions[1]];
      this.form.district = CodeToText[this.form.selectedOptions[2]];
      const formData = new FormData();
      for (let key in this.form) {
        if (
          this.form[key] === "" ||
          (Array.isArray(this.form[key]) && this.form[key].length < 1) ||
          key.includes("Url") ||
          key === "repwd"
        ) {
          continue;
        }
        formData.append(
          key,
          Array.isArray(this.form[key])
            ? this.form[key][0]?.raw
            : this.form[key]
        );
      }
      if (this.form.idCardF.length == 0) {
        alert("请上传身份证正反面文件");
      } else if (this.form.certF.length == 0) {
        alert("请上传公司授权委托书");
      } else if (this.form.tech !== "无" && this.form.techF.length == 0) {
        alert("请上传高新技术企业认证文件");
      } else if (
        this.form.otherList.includes(0) &&
        this.form.otherSeurityF.length == 0
      ) {
        alert("请上传涉密系统集成资质认证文件");
      } else if (
        this.form.otherList.includes(1) &&
        this.form.otherTwoF.length == 0
      ) {
        alert("请上传双软企业认证文件");
      } else if (
        this.form.otherList.includes(2) &&
        this.form.otherCmmitF.length == 0
      ) {
        alert("请上传CMMI认证文件");
      } else if (
        this.form.otherList.includes(3) &&
        this.form.otherItssF.length == 0
      ) {
        alert("请上传ITSS认证文件");
      } else if (
        this.form.otherList.includes(4) &&
        this.form.otherCsF.length == 0
      ) {
        alert("请上传CS认证文件");
      } else if (
        this.form.otherList.includes(5) &&
        this.form.otherIsoF.length == 0
      ) {
        alert("请上传ISO认证文件");
      } else {
        await this.$api.registerMemberC(formData).then((res) => {
          if (res.state == 200) {
            _this.$message.success(res.message);
            this.$router.push("/login");
          } else {
            _this.$message.error(res.message);
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">
.b-register-view {
  .el-form-item {
    position: relative;
    .show {
      position: absolute;
      right: -70px;
      top: 7px;
      width: 56px;
      height: 30px;
      text-align: center;
      line-height: 32px;
      color: #fff;
      font-size: 12px;
      background-color: #3883ff;
      cursor: pointer;
    }
  }
  .el-form-item__label {
    font-size: 20px;
    font-weight: 400;
    color: #333333;
    white-space: nowrap;
  }
  .el-form-item__content,
  .el-input,
  .el-radio__label {
    font-size: 16px;
    font-weight: 400;
    color: #333333;
  }

  .image-item {
    margin-bottom: 32px;
    width: 206px;
    height: 240px;
    &.logo-item {
      margin-bottom: 0;
      width: 150px;
      height: 150px;
    }
    &.idCard-item {
      width: 240px;
      height: 160px;
    }
    &.showImage {
      .el-upload {
        display: none;
      }
    }
    .el-upload--picture,
    .el-upload-list--picture {
      width: 100%;
      height: 100%;
    }
    .el-upload-list__item {
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      transition: none;
      img {
        margin: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .el-icon-close {
        background-color: #fff;
        border-radius: 50%;
        z-index: 10;
      }
    }
  }
  .addImage {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    color: #4b555b;
    width: 100%;
    height: 100%;
    line-height: 22px;
    background: #ffffff;
    border: 1px solid #dadada;
    &:before {
      margin-top: 12px;
      margin-bottom: 24px;
      content: " ";
      width: 24px;
      height: 2px;
      background-color: #666666;
    }
    &:after {
      position: absolute;
      top: 50%;
      margin-top: -29px;
      content: " ";
      width: 2px;
      height: 24px;
      background-color: #666666;
    }
  }

  .file-item {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    .el-upload-list {
      width: 150px;
    }
    .el-upload {
      margin-left: 20px;
    }
  }
  .el-input {
    width: 225px;
    .el-input__inner {
      height: 34px;
    }
    &.el-input--suffix {
      .el-input__inner {
        padding-right: 55px;
      }
      .el-input__suffix {
        right: 16px;
      }
    }
  }
  .form-title {
    margin-bottom: 48px;
    .el-form-item__label {
      line-height: 28px;
    }
  }
  .sub-title {
    margin-top: 52px;
    margin-bottom: 32px;
    .el-form-item__label {
      color: #fa6400;
      line-height: 28px;
    }
  }
  .form-action {
    margin-top: 40px;
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-message-box {
  border: 0;
  padding: 0;
  width: 500px;
  height: 500px;
}
.el-dialog__headerbtn {
  font-size: 40px;
}
</style>
